var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.useDeprecated ? _c('c-select', {
    domProps: {
      "allowCreate": _vm.allowCreate,
      "cValue": _vm.value,
      "chipColor": _vm.computedColor,
      "chipTextColor": _vm.computedChipTextColor,
      "clearable": _vm.clearable,
      "createText": _vm.createText,
      "disabled": _vm.disabled,
      "errorMessage": _vm.computedErrorMessage,
      "label": _vm.label,
      "multiple": _vm.multiple,
      "required": _vm.required,
      "selectOptions": _vm.computedSelectOptions,
      "sort": _vm.sort
    },
    on: {
      "c-clear": _vm.emitInlineClear,
      "c-input": function ($event) {
        return _vm.emitInput($event.detail);
      },
      "c-search": function ($event) {
        return _vm.onUpdateOfSearchInput($event.detail);
      },
      "c-option-create": function ($event) {
        return _vm.emitCreateEvent($event.detail);
      }
    }
  }, [_vm._l(_vm.computedSelectOptions, function (option) {
    return _c('div', {
      key: `single-select-option-${option.value}`,
      attrs: {
        "slot": `single-select-option-${option.value}`
      },
      slot: `single-select-option-${option.value}`
    }, [_vm.$scopedSlots['select-option'] ? _vm._t("select-option", null, {
      "option": option
    }) : _c('span', {
      class: {
        monospace_font: _vm.monospaceSelections
      }
    }, [_vm._v(_vm._s(option.displayText))])], 2);
  }), _vm._l(_vm.computedSelectOptions, function (option) {
    return _c('div', {
      key: `multi-select-option-${option.value}`,
      attrs: {
        "slot": `multi-select-option-${option.value}`
      },
      slot: `multi-select-option-${option.value}`
    }, [_vm.$scopedSlots['select-option'] ? _vm._t("select-option", null, {
      "option": option
    }) : _c('span', {
      class: {
        monospace_font: _vm.monospaceSelections
      }
    }, [_vm._v(_vm._s(option.displayText))])], 2);
  }), _vm._l(_vm.computedSelectOptions, function (option) {
    return _c('div', {
      key: `selected-chip-content-${option.value}`,
      attrs: {
        "slot": `selected-chip-content-${option.value}`
      },
      slot: `selected-chip-content-${option.value}`
    }, [_vm.$scopedSlots['selected-chip-content'] ? _vm._t("selected-chip-content", null, {
      "option": option
    }) : _c('span', [_vm._v(_vm._s(option.displayText))])], 2);
  }), _vm.$scopedSlots['message'] ? _c('div', {
    attrs: {
      "slot": "message"
    },
    slot: "message"
  }, [_vm._t("message")], 2) : _vm._e(), _c('div', {
    attrs: {
      "slot": "after-input"
    },
    slot: "after-input"
  }, [_vm._t("after-input")], 2)], 2) : _vm.autocomplete ? _c('v-autocomplete', _vm._b({
    attrs: {
      "cache-items": !_vm.disableCache,
      "color": _vm.computedColor,
      "clear-icon": _vm.$icons.clear,
      "items": _vm.computedSelectOptions,
      "messages": _vm.computedMessages,
      "outline": _vm.computedOutlineProp,
      "rules": _vm.localRulesBaseOnProps,
      "search-input": _vm.searchValue,
      "item-text": "displayText",
      "item-value": "value"
    },
    on: {
      "update:searchInput": [function ($event) {
        _vm.searchValue = $event;
      }, _vm.onUpdateOfSearchInput],
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      },
      "change": _vm.emitInput,
      "click:clear": _vm.emitInlineClear,
      "input": _vm.emitSelectionChanged
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitInlineClear.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (props) {
        return [_vm._t("item", function () {
          return [_c('div', {
            class: {
              'text-truncate': _vm.hasUserDefinedWidth,
              monospace_font: _vm.monospaceSelections
            },
            style: `width: calc(${_vm.width} + 14px)`
          }, [_c('span', [_c('v-fade-transition', {
            attrs: {
              "hide-on-leave": ""
            }
          }, [props.tile.props.value ? _c('c-icon-fa', {
            staticClass: "icon_selected_option_is_selected",
            attrs: {
              "icon": _vm.$icons.square_check,
              "icon-color": "var(--v-success-base)",
              "icon-size": "16px"
            }
          }) : _vm._e()], 1)], 1), props.tile.props.value ? _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "font-weight-bold black--text ml-2",
            attrs: {
              "tooltip": "Currently selected"
            }
          }) : _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "ml-4"
          })])];
        }, null, props.item)];
      }
    }, {
      key: "selection",
      fn: function (props) {
        return [_vm._t("selection", function () {
          return [_vm.chips ? _c('v-chip', {
            attrs: {
              "color": _vm.computedColor,
              "text-color": _vm.computedChipTextColor,
              "close": _vm.deletableChips
            }
          }, [_c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "font-weight-bold"
          })]) : _vm._e(), !_vm.chips ? _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: _vm.displaySelection(props.item.displayText),
              expression: "displaySelection(props.item.displayText)"
            }],
            staticClass: "font-weight-bold",
            class: {
              'text-truncate': _vm.hasUserDefinedWidth,
              monospace_font: _vm.monospaceSelections
            },
            style: `max-width: calc(${_vm.width} - 48px)`
          }) : _vm._e()];
        }, null, props.item)];
      }
    }, _vm.$slots['no-data'] ? {
      key: "no-data",
      fn: function () {
        return [_vm._t("no-data")];
      },
      proxy: true
    } : null], null, true)
  }, 'v-autocomplete', {
    ..._vm.$attrs,
    ..._vm.$props
  }, false), [_c('template', {
    slot: "label"
  }, [_c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.label,
      expression: "label"
    }]
  })]), _vm.allowCreate && _vm.searchValueIsUnique && _vm.searchValue ? _c('template', {
    slot: "append-item"
  }, [_c('ButtonCreate', {
    on: {
      "click": _vm.emitCreateEvent
    }
  }, [_c('span', [_vm._v("Create " + _vm._s(_vm.searchValue) + " " + _vm._s(_vm.createText))])])], 1) : _vm._e()], 2) : _c('v-select', _vm._b({
    attrs: {
      "color": _vm.computedColor,
      "items": _vm.computedSelectOptions,
      "messages": _vm.computedMessages,
      "outline": _vm.computedOutlineProp,
      "clear-icon": _vm.$icons.clear,
      "rules": _vm.localRulesBaseOnProps,
      "item-text": "displayText",
      "item-value": "value"
    },
    on: {
      "change": _vm.emitInput,
      "click:clear": _vm.emitInlineClear
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitInlineClear.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (props) {
        return [_vm._t("item", function () {
          return [_c('div', {
            class: {
              'text-truncate': _vm.hasUserDefinedWidth,
              monospace_font: _vm.monospaceSelections
            },
            style: `width: calc(${_vm.width} + 14px)`
          }, [_c('span', [_c('v-fade-transition', {
            attrs: {
              "hide-on-leave": ""
            }
          }, [props.tile.props.value ? _c('c-icon-fa', {
            staticClass: "icon_selected_option_is_selected",
            attrs: {
              "icon": _vm.$icons.square_check,
              "icon-size": "16px",
              "icon-color": "var(--v-success-base)"
            }
          }) : _vm._e()], 1)], 1), props.tile.props.value ? _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "font-weight-bold black--text ml-2",
            attrs: {
              "tooltip": "Currently selected"
            }
          }) : _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "ml-4"
          })])];
        }, null, props.item)];
      }
    }, {
      key: "selection",
      fn: function (props) {
        return [_vm._t("selection", function () {
          return [_vm.chips ? _c('v-chip', {
            attrs: {
              "color": _vm.computedColor,
              "text-color": _vm.computedChipTextColor,
              "close": _vm.deletableChips
            }
          }, [_c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "font-weight-bold"
          })]) : _vm._e(), !_vm.chips ? _c('span', {
            directives: [{
              name: "safe-html",
              rawName: "v-safe-html",
              value: props.item.displayText,
              expression: "props.item.displayText"
            }],
            staticClass: "font-weight-bold",
            class: {
              'text-truncate': _vm.hasUserDefinedWidth,
              monospace_font: _vm.monospaceSelections
            },
            style: `max-width: calc(${_vm.width} - 48px)`
          }) : _vm._e()];
        }, null, props.item)];
      }
    }], null, true)
  }, 'v-select', _vm.propsMinusAutoComplete, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };