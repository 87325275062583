var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--wrapper"
  }, [_vm.hardwareData && !_vm.isLoading ? _c('div', [_c('div', {
    staticClass: "ahd--grid-container"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._drawingNumberDisplay, _vm.drawingNumberHref),
      "label": "Drawing num:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._asBuiltNumberDisplay, _vm.asBuiltNoHref),
      "label": "AsBuilt:",
      "monospace-font": !_vm._asBuiltNumberDisplayIsDefault
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._serialNumberDisplay, _vm.serialLotHref),
      "label": "Serial:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._lotNumberDisplay, _vm.serialLotHref),
      "label": "Lot:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sideDisplay,
      "label": "Side:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sizeDisplay,
      "label": "Size:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref('View Changelogs', _vm._changelogHref),
      "label": "History:"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._itemClassDisplay,
      "label": "Class:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._federalSupplyClassDisplayCode,
      "label": "Fed cls:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._objectClassDisplay,
      "label": "Obj cls:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._propertyClassDisplay,
      "label": "Prop cls:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "label": "Equip grp:",
      "text": _vm._equipmentGroupDisplay,
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiNumberDisplay,
      "label": "CEI no:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiLevelDisplay,
      "label": "CEI lvl:",
      "monospace-font": ""
    }
  }), _vm.shouldShow(_vm._computedEquipmentControlNumber) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedEquipmentControlNumber,
      "label": "ECN:"
    }
  }) : _vm._e(), _vm.hasSoftwareVersion ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._softwareVersion,
      "label": "Soft. version:"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-three"
  }, [_vm.shouldShow(_vm._birthDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._birthDateDisplay,
      "label": "Birth:",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._operationalStartDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._operationalStartDateDisplay,
      "label": "Op start:",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._acquisitionDateDisplay) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._acquisitionDateDisplay,
      "label": "Acq:",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._computedLastServiceDate) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedLastServiceDate,
      "label": "Last svc:",
      "monospace-font": ""
    }
  }) : _vm._e(), _vm.shouldShow(_vm._computedLastInspectionDate) ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._computedLastInspectionDate,
      "label": "Last insp:",
      "monospace-font": ""
    }
  }) : _vm._e()], 1), _vm.hasLimitedLife ? _c('div', {
    staticClass: "ahd--column-four"
  }, [_vm.hasLimitedLifeShelf ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._shelfLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifeUsage ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._usageLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifeOperation ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._operationLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifePressurizedTime ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._pressurizedTimeLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifeInterval ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._intervalLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifeOnOrbit ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._onOrbitLifeDisplay
    }
  }) : _vm._e(), _vm.hasLimitedLifeStatic ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm._staticLifeDisplay
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "ahd--column-five"
  }, [_vm._initializationStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._initializationStatus ? 'Initialized' : 'Not initialized',
      "label": "Initialization:",
      "tooltipLabel": "Initialization status"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._initializationStatus,
            callback: function ($$v) {
              _vm._initializationStatus = $$v;
            },
            expression: "_initializationStatus"
          }
        })];
      },
      proxy: true
    }], null, false, 2967205490)
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign',
      "label": "Approved AD:",
      "tooltipLabel": "Is Approved AsDesign"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          attrs: {
            "title": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign'
          },
          model: {
            value: _vm.isApprovedAsDesign,
            callback: function ($$v) {
              _vm.isApprovedAsDesign = $$v;
            },
            expression: "isApprovedAsDesign"
          }
        })];
      },
      proxy: true
    }], null, false, 1927003056)
  }), _vm._hazardousMaterialStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._hazardousMaterialText,
      "label": "Hazard. Mat."
    }
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._limitedLifeAlertCountIsGreaterThanZero ? 'There are existing Limited Life Alerts' : 'No Limited Life alerts',
      "label": "LL Alerts:",
      "tooltipLabel": "Limited Life Alerts"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._limitedLifeAlertCountIsGreaterThanZero ? _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-warning-base)",
            "icon": _vm.$icons.warning,
            "tooltip": "There are existing Limited Life alerts."
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-success-base)",
            "icon": _vm.$icons.APPROVED,
            "tooltip": "No Limited Life alerts"
          }
        })];
      },
      proxy: true
    }], null, false, 542860468)
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._hasOpenPaperAssy ? 'There is existing Open Paperwork' : 'No open paperwork',
      "label": "Open Paper:",
      "tooltipLabel": "Open Paperwork"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._hasOpenPaperAssy ? _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-warning-base)",
            "icon": _vm.$icons.warning,
            "tooltip": "There is existing Open Paperwork"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon-color": "var(--v-success-base)",
            "icon": _vm.$icons.APPROVED,
            "tooltip": "No open paperwork"
          }
        })];
      },
      proxy: true
    }], null, false, 1473457424)
  })], 1), _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('AHDQuantityColumn', {
    attrs: {
      "available-quantity": _vm._availableQuantity,
      "available-quantity-link": _vm.quantityHref,
      "installed-quantity": _vm._installedQuantity,
      "installed-quantity-link": _vm.quantityHref,
      "inventory-quantity": _vm._inventoryQuantity,
      "inventory-quantity-link": _vm.itemInstanceInventoryQuantityHref,
      "inventory-method": _vm.unabbreviatedMethodDisplay
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Description:")]), _c('a', {
    staticClass: "font-weight-bold",
    attrs: {
      "href": _vm.drawingNumberHref,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._description))])]), _vm.isHazardousMaterial ? _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Hazard. Mat. Text:")]), _c('b', [_vm._v(_vm._s(_vm._hazardousMaterialText))])]) : _vm._e(), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Current Loc:")]), _vm._l(_vm._currentLocationsForIIGroupedByProjectCode, function (projectCodeGroup, index) {
    return _c('span', {
      key: index
    }, [_c('div', [_c('strong', {
      staticClass: "monospace_font",
      attrs: {
        "title": "Project code"
      }
    }, [_vm._v(_vm._s(projectCodeGroup.projectCode || '-'))])]), _c('div', {
      directives: [{
        name: "safe-html",
        rawName: "v-safe-html",
        value: projectCodeGroup._currentLocations,
        expression: "projectCodeGroup._currentLocations"
      }]
    })]);
  }), !_vm._currentLocationsForIIGroupedByProjectCode || _vm._currentLocationsForIIGroupedByProjectCode.length === 0 ? _c('div', [_c('strong', {
    attrs: {
      "title": `Project code ${_vm._projectCodeDisplay}`
    }
  }, [_vm._v("-")])]) : _vm._e()], 2), _c('div', {
    staticClass: "ahd--button-wrapper"
  }, [_vm.shouldShowExternalLinksButton ? _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('v-btn', _vm._g({
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": "View Links"
          }
        }, on), [_c('i', {
          staticClass: "pt-2",
          class: _vm.isViewLinksMenuOpen ? 'fa-rotate-90 fal fa-angle-left ' : 'fa-rotate-90 fal fa-angle-right ',
          staticStyle: {
            "font-size": "16px"
          }
        }), _c('c-icon-fa', {
          staticClass: "pr-2 pl-2",
          attrs: {
            "icon": _vm.$icons['link']
          }
        }), _vm._v(" View Links ")], 1)];
      }
    }], null, false, 4186072215),
    model: {
      value: _vm.isViewLinksMenuOpen,
      callback: function ($$v) {
        _vm.isViewLinksMenuOpen = $$v;
      },
      expression: "isViewLinksMenuOpen"
    }
  }, [_c('v-list', {
    attrs: {
      "dark": ""
    }
  }, _vm._l(_vm.linksFormatted, function (link, index) {
    return _c('v-list-tile', {
      key: index,
      attrs: {
        "href": link.url,
        "title": link.url
      }
    }, [_c('v-list-tile-title', [_c('c-icon-fa', {
      staticClass: "pr-2",
      attrs: {
        "icon-size": "16px",
        "icon": _vm.$icons.link
      }
    }), _c('span', [_vm._v(_vm._s(link.name))])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.ITEM_INSTANCE
    }
  }) : _vm._e(), !_vm.isLoading && !_vm.hardwareData ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" Could not load data for this piece of Hardware. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };