var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.hasAnyCreateItems ? _c('v-menu', {
    staticClass: "create_menu",
    attrs: {
      "max-height": "90vh",
      "offset-y": "",
      "left": "",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on: menu
      }) {
        return [_c('v-btn', _vm._g({
          staticClass: "create-button",
          attrs: {
            "icon": "",
            "tooltip": "Create COSMIC items",
            "ripple": false
          },
          on: {
            "mouseover": function ($event) {
              _vm.isHoveringOverCreateMenuIcon = true;
            },
            "mouseleave": function ($event) {
              _vm.isHoveringOverCreateMenuIcon = false;
            }
          }
        }, {
          ...menu
        }), [!_vm.isHoveringOverCreateMenuIcon ? _c('c-icon-fa', {
          attrs: {
            "icon": _vm.CosmicIcons.create,
            "icon-color": "white",
            "icon-size": "22px"
          }
        }) : _c('c-icon-fa', {
          attrs: {
            "icon": _vm.CosmicIcons.create,
            "icon-color": "black",
            "icon-size": "22px"
          }
        }), _c('span', {
          staticClass: "assistive-text"
        }, [_vm._v("Create")])], 1)];
      }
    }], null, false, 931741945)
  }, [_c('v-list', _vm._l(_vm.selectOptions, function (option, i) {
    return _c('v-list-tile', {
      key: i,
      attrs: {
        "href": option.destinationUrl
      },
      on: {
        "click": function ($event) {
          return _vm.onClickOfCreateItem(option, $event);
        }
      }
    }, [_c('v-list-tile-action', {
      staticStyle: {
        "justify-content": "center"
      }
    }, [_c('c-icon-fa', {
      attrs: {
        "icon": option.icon,
        "icon-color": option.textColor,
        "icon-size": "22px"
      }
    })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(option.displayText))])], 1)], 1);
  }), 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };