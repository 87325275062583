var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "ahd--wrapper"
  }, [_vm.hardwareData && !_vm.isLoading ? _c('div', {
    attrs: {
      "data-item-master-ahd": ""
    }
  }, [_c('div', {
    staticClass: "ahd--grid-container"
  }, [_c('div', {
    staticClass: "ahd--column-one"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._drawingNumberDisplay, _vm.drawingNumberHref),
      "label": "Drawing num:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref(_vm._asBuiltNumberDisplay, _vm.asBuiltNoHref),
      "label": "AsBuilt:",
      "monospace-font": !_vm._asBuiltNumberDisplayIsDefault
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._sideDisplay,
      "label": "Side:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._itemMasterSizes,
      "label": "Valid sizes:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "vhtml": _vm.formatValuesForHref('View Changelogs', _vm._changelogHref),
      "tooltipText": "View changes made in the past 12 months",
      "label": "History:",
      "tooltipLabel": "History"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-two"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._federalSupplyClassDisplayCode,
      "label": "Fed cls:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._objectClassDisplay,
      "label": "Obj cls:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._propertyClassDisplay,
      "label": "Prop cls:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._equipmentGroupDisplay,
      "tooltipText": _vm._equipmentGroupTooltipDisplay,
      "label": "Equip grp:",
      "tooltipLabel": "Equipment group"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiNumberDisplay,
      "label": "CEI no:",
      "monospace-font": ""
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._ceiLevelDisplay,
      "label": "CEI lvl:",
      "monospace-font": ""
    }
  }), _vm.hasSoftwareVersion ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._softwareVersion,
      "label": "Soft. version:"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-three"
  }, [_c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._criticalityCodeDisplay,
      "label": "Crit code:"
    }
  }), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "text": _vm._opsNom,
      "label": "Ops Nom:"
    }
  })], 1), _c('div', {
    staticClass: "ahd--column-five"
  }, [_vm._initializationStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._initializationStatus ? 'Initialized' : 'Not initialized',
      "label": "Initialization:",
      "tooltipLabel": "Initialization status"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._initializationStatus,
            callback: function ($$v) {
              _vm._initializationStatus = $$v;
            },
            expression: "_initializationStatus"
          }
        })];
      },
      proxy: true
    }], null, false, 2967205490)
  }) : _vm._e(), _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign',
      "label": "Approved AD:",
      "tooltipLabel": "Is Approved AsDesign"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          attrs: {
            "title": _vm.isApprovedAsDesign ? 'Yes, approved AsDesign' : 'Not approved AsDesign'
          },
          model: {
            value: _vm.isApprovedAsDesign,
            callback: function ($$v) {
              _vm.isApprovedAsDesign = $$v;
            },
            expression: "isApprovedAsDesign"
          }
        })];
      },
      proxy: true
    }], null, false, 1927003056)
  }), _vm._specialPackagingStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._specialPackagingStatus ? 'Yes' : 'No',
      "label": "Spc. Packaging:",
      "tooltipLabel": "Special Packaging Status?"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._specialPackagingStatus ? _c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._specialPackagingStatus,
            callback: function ($$v) {
              _vm._specialPackagingStatus = $$v;
            },
            expression: "_specialPackagingStatus"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 2917570134)
  }) : _vm._e(), _vm._toolStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._toolStatus ? 'Yes' : 'No',
      "label": "Tool:",
      "tooltipLabel": "Is tool?"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._toolStatus ? _c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._toolStatus,
            callback: function ($$v) {
              _vm._toolStatus = $$v;
            },
            expression: "_toolStatus"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 3638982486)
  }) : _vm._e(), _vm._hazardousMaterialStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._hazardousMaterialText,
      "label": "Hazard. Mat.:",
      "tooltipLabel": "Hazardous Material"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_c('c-icon-fa', {
          attrs: {
            "tooltip": _vm._hazardousMaterialText,
            "icon-color": "var(--v-warning-base)",
            "icon": _vm.$icons.warning
          }
        })];
      },
      proxy: true
    }], null, false, 316546319)
  }) : _vm._e(), _vm._fractureCriticalStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._fractureCriticalStatus ? 'Yes' : 'No',
      "label": "Hazard. Mat.:",
      "tooltipLabel": "Frac Critical"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._fractureCriticalStatus ? _c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._fractureCriticalStatus,
            callback: function ($$v) {
              _vm._fractureCriticalStatus = $$v;
            },
            expression: "_fractureCriticalStatus"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 2146771958)
  }) : _vm._e(), _vm._cleanlinessTrackingStatus ? _c('AppHardwareDisplayGridItem', {
    attrs: {
      "tooltipText": _vm._cleanlinessTrackingStatus ? 'Yes' : 'No',
      "label": "Clean. Tracking:",
      "tooltipLabel": "Clean. Tracking"
    },
    scopedSlots: _vm._u([{
      key: "text-content-slot",
      fn: function () {
        return [_vm._cleanlinessTrackingStatus ? _c('AppBooleanDisplay', {
          staticClass: "d-inline-block",
          model: {
            value: _vm._cleanlinessTrackingStatus,
            callback: function ($$v) {
              _vm._cleanlinessTrackingStatus = $$v;
            },
            expression: "_cleanlinessTrackingStatus"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 3250549494)
  }) : _vm._e()], 1), _c('div', {
    staticClass: "ahd--column-six"
  }, [_c('AHDQuantityColumn', {
    attrs: {
      "available-quantity": _vm._availableQuantity,
      "available-quantity-link": _vm.quantityHref,
      "installed-quantity": _vm._installedQuantity,
      "installed-quantity-link": _vm.quantityHref,
      "inventory-quantity": _vm._inventoryQuantity,
      "inventory-quantity-link": _vm.itemMasterInventoryQuantityHref,
      "inventory-method": _vm.unabbreviatedMethodDisplay
    }
  })], 1)]), _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Description:")]), _c('a', {
    staticClass: "font-weight-bold",
    attrs: {
      "href": _vm.drawingNumberHref,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._description))])]), _vm.isHazardousMaterial ? _c('div', {
    staticClass: "ahd--solo-row"
  }, [_c('p', {
    staticClass: "desc--label"
  }, [_vm._v("Hazard. Mat. Text:")]), _c('b', [_vm._v(_vm._s(_vm._hazardousMaterialText))])]) : _vm._e(), _c('div', {
    staticClass: "ahd--button-wrapper"
  }, [_vm.shouldShowExternalLinksButton ? _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('v-btn', _vm._g({
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": "View Links"
          }
        }, on), [_c('c-icon-fa', {
          staticClass: "pt-2",
          attrs: {
            "icon-size": "16px",
            "icon": _vm.isViewLinksMenuOpen ? 'fa-rotate-90 fal fa-angle-left ' : 'fa-rotate-90 fal fa-angle-right '
          }
        }), _c('c-icon-fa', {
          staticClass: "pr-2 pl-2",
          attrs: {
            "icon": _vm.$icons.link
          }
        }), _vm._v(" View Links ")], 1)];
      }
    }], null, false, 310777259),
    model: {
      value: _vm.isViewLinksMenuOpen,
      callback: function ($$v) {
        _vm.isViewLinksMenuOpen = $$v;
      },
      expression: "isViewLinksMenuOpen"
    }
  }, [_c('v-list', {
    attrs: {
      "dark": ""
    }
  }, _vm._l(_vm.linksFormatted, function (link, index) {
    return _c('v-list-tile', {
      key: index,
      attrs: {
        "href": link.url,
        "title": link.url
      }
    }, [_c('v-list-tile-title', [_c('c-icon-fa', {
      staticClass: "pr-2",
      attrs: {
        "icon-size": "16px",
        "icon": _vm.$icons.link
      }
    }), _c('span', [_vm._v(_vm._s(link.name))])], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _vm.isArchived ? _c('div', {
    staticClass: "ahd--solo-row mt-2"
  }, [_c('AppPageDescription', {
    attrs: {
      "color": "error"
    }
  }, [_c('strong', {
    staticClass: "error--text lighten-2"
  }, [_vm._v("Archived")])])], 1) : _vm._e()]) : _vm._e(), _vm.isLoading ? _c('AppLoader', {
    attrs: {
      "type": "linear",
      "color": _vm.EntityType.ITEM_MASTER
    }
  }) : _vm._e(), !_vm.isLoading && !_vm.hardwareData ? _c('Alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v(" Could not load data for this piece of Hardware. ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };