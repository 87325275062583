import "core-js/modules/es.string.replace-all.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    attrs: {
      "data-item-master-mini": ""
    }
  }, [_vm.computedTitle ? _c('AppHeader', {
    attrs: {
      "size": "mini"
    }
  }, [_vm._v(_vm._s(_vm.computedTitle))]) : _vm._e(), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.hasLocalEntity ? _c('div', {
    staticClass: "entity-mini",
    class: {
      [_vm.color]: _vm.color,
      hasNoActions: !_vm.hasAnyActions,
      'no-entity-icon': !_vm.showEntityTypeIcon
    }
  }, [_vm.showEntityTypeIcon ? _c('c-icon-fa', {
    staticClass: "entity-mini-icon",
    attrs: {
      "icon": _vm.$icons[_vm.EntityType.ITEM_MASTER],
      "icon-size": "32px",
      "icon-color": "white"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "entity-mini-name text-truncate white--text"
  }, [_c('div', {
    staticClass: "mini-name text-truncate display-1",
    attrs: {
      "data-im-mini-name": ""
    }
  }, [_vm._v(_vm._s(_vm.name))]), _vm.shouldShowSubText ? _c('small', {
    staticClass: "caption pl-1",
    attrs: {
      "data-mini-subtext": "",
      "title": _vm.subNameText
    }
  }, [_vm._v(_vm._s(_vm.subNameText))]) : _vm._e()]), _c('div', {
    staticClass: "vertical-display-item white--text item_master_equipment_group"
  }, [_vm.localEntity ? _c('div', {
    staticClass: "text-capitalize mb-1"
  }, [_vm._v("Equipment group")]) : _vm._e(), _c('div', {
    staticClass: "font-weight-bold equipment_group_value",
    attrs: {
      "data-mini-equipment-group": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.equipmentGroupDisplay) + " ")])]), _c('div', {
    staticClass: "vertical-display-items hidden-sm-and-down white--text",
    class: {
      is_archived: _vm.isArchived
    }
  }, [_vm._l(_vm.verticalLabelDisplayItems, function (verticalDisplayItem) {
    return _c('div', {
      key: verticalDisplayItem.name,
      staticClass: "vertical-display-item text-truncate",
      class: verticalDisplayItem.class,
      style: verticalDisplayItem.style,
      attrs: {
        "title": verticalDisplayItem.title || verticalDisplayItem.value || ''
      }
    }, [_c('div', {
      staticClass: "mb-1"
    }, [_vm._v(_vm._s(verticalDisplayItem.name))]), _c('div', {
      class: `vertical-display-item-value mini-${verticalDisplayItem.name.replaceAll(' ', '-').toLowerCase().trim()}`
    }, [_vm._v(" " + _vm._s(verticalDisplayItem.value || '-') + " ")])]);
  }), _c('div', {
    staticClass: "vertical-display-item init_status white--text"
  }, [_vm.localEntity ? _c('div', {
    staticClass: "mb-1"
  }, [_vm._v("Init status")]) : _vm._e(), _c('span', {
    directives: [{
      name: "safe-html",
      rawName: "v-safe-html",
      value: _vm.initializationStatusIconDisplay,
      expression: "initializationStatusIconDisplay"
    }]
  })]), _vm.isArchived ? _c('div', {
    staticClass: "vertical-display-item text-xs-center"
  }, [_c('c-icon-fa', {
    class: _vm.$icons.archived,
    attrs: {
      "icon-color": "#fff",
      "tooltip": "This Item Master has been archived."
    }
  })], 1) : _vm._e()], 2), _c('div', {
    staticClass: "mini_actions"
  }, [_vm.localEntity ? _c('ButtonMiniActionInfo', {
    staticClass: "mr-2",
    attrs: {
      "icon-color": "white"
    },
    on: {
      "click": function ($event) {
        _vm.showAhd = !_vm.showAhd;
      }
    }
  }) : _vm._e(), _vm.shouldShowManageLink ? _c('ButtonMiniActionManage', {
    staticClass: "mr-2",
    attrs: {
      "href": _vm.manageLink,
      "tooltip": _vm.manageLinkTitle,
      "is-link": "",
      "icon-color": "white"
    }
  }) : _vm._e(), _vm.shouldShowInlineClearIcon ? _c('ButtonMiniAction', {
    attrs: {
      "icon": _vm.inlineClearIcon
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onClickOfInlineClear.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showAhd && _vm.localEntity ? _c('AppFormWrapper', {
    attrs: {
      "color": _vm.entityType,
      "bottom": "1",
      "color-modifier": "",
      "left": "1",
      "right": "1",
      "top": "0"
    }
  }, [_c('AppHardwareDisplay', {
    attrs: {
      "node-id": _vm.localEntity.nodeId,
      "hide-border-bottom": ""
    }
  })], 1) : _vm._e()], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.isFetching ? _c('AppLoader', {
    key: "entity-loader",
    attrs: {
      "color": _vm.EntityType.ITEM_MASTER,
      "type": "linear",
      "center": ""
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };